import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { getContentfulData } from "../hook/getContentfulData";
import Marquee from "react-fast-marquee";

export default function Tab() {
  const [ToggleState, setToggleState] = useState(1);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [category, setCategory] = useState([]);
  const [indirectInvestment, setIndirectInvestment] = useState([]);
  const halfLength = Math.ceil((indirectInvestment?.length || 0) / 2);
  const query = `{
    portfolioPageCollection(limit:1){
      items{
        addProjectCollection{
          items{
            name
            link
            image{
              url
            }

            chooseCategoryCollection{
              items{
                categoryName
              }
            }
          }
        }

        indirectInvestmentProjectsCollection{
          items{
            name
            image{
              url
            }
          }
        }
      }
    }
  }`;

  const query2 = `{
    categoryCollection {
      items {
        categoryName
      }
    }
  }`;

  const toggleTab = (index, itemname) => {
    if (index == 1) {
      setData(allData);
    } else {
      const temp = allData.filter((item) =>
        item?.chooseCategoryCollection.includes(itemname)
      );
      setData(temp);
    }
    setToggleState(index);
  };

  const getActiveClass = (index, className) =>
    ToggleState === index ? className : "";

  const handleGetCAtegories = async () => {
    const res = await getContentfulData(query2);
    const {
      data: {
        categoryCollection: { items },
      },
    } = res;
    if (items.length > 0) {
      const temp = items.map((item) => item?.categoryName);
      const newTemp = ["All", ...temp.reverse()];
      setCategory(newTemp);
    }
  };
  
  const handleGetPortfolio = async () => {
    const res = await getContentfulData(query);
    if (res?.data?.portfolioPageCollection?.items) {
      const items = res.data.portfolioPageCollection.items;

      if (items[0]?.addProjectCollection.items.length > 0) {
        const newItemList = items[0]?.addProjectCollection.items
          .map((item) => ({
            ...item,
            chooseCategoryCollection:
              item?.chooseCategoryCollection?.items.length > 0
                ? item?.chooseCategoryCollection?.items.map(
                    (item) => item?.categoryName
                  )
                : [],
          }))
          .sort((a, b) => {
            if (!a.name && !b.name) return 0; // Both names are undefined, considered equal
            if (!a.name) return -1; // Undefined names will be placed at the start
            if (!b.name) return 1; // Undefined names will be placed at the start
            return a.name.localeCompare(b.name);
          }); // Updated sorting logic

        setAllData(newItemList);
        setData(newItemList);

        if (items[0]?.indirectInvestmentProjectsCollection.items.length > 0) {
          setIndirectInvestment(
            items[0]?.indirectInvestmentProjectsCollection.items
              .sort((a, b) => {
                if (!a.name && !b.name) return 0; // Both names are undefined, considered equal
                if (!a.name) return -1; // Undefined names will be placed at the start
                if (!b.name) return 1; // Undefined names will be placed at the start
                return a.name.localeCompare(b.name);
              }) // Sorting indirectInvestmentProjectsCollection items with updated logic
          );
        }
      }
    }
  };

  useEffect(() => {
    handleGetPortfolio();
    handleGetCAtegories();
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 8,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 8,
      slidesToSlide: 8, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 8,
      slidesToSlide: 8, // optional, default to 1.
    },
  };

  return (
    <div className="tabs-container">
      <ul className="tab-list">
        {category.length > 0 &&
          category.map((item, index) => (
            <li
              className={`tabs ${getActiveClass(index + 1, "active-tabs")}`}
              onClick={() => toggleTab(index + 1, item)}
              key={index}
            >
              {item}
            </li>
          ))}
      </ul>
      <div className="content-container">
        <div className={`content active-content lg:mb-40 md:mb-40 sm:mb-20 mb-10`}>
          <div className="icons mt-5">
            {data.length > 0 &&
              data.map((item, i) => (
                item?.image?.url && (
                  <div className="icon-wrapper" key={i}>
                    <a target="_blank" href={item?.link}>
                      <div className="icon-image">
                        <img src={item?.image?.url} alt={item?.name} />
                      </div>
                      <div className="icon-text">
                        {item?.name}
                      </div>
                    </a>
                  </div>
                )
              ))
            }
            <div className="indirect-full-w">
              <h4 className="indirect">INDIRECT INVESTMENTS</h4>
              <p className="indirect-sub">Through our DAOs</p>
            </div>
            <div className="icons2">
              <Marquee speed='30'>
                {indirectInvestment?.slice(0, halfLength).map((item, i) => (
                  <div className="slide" key={i}>
                    <div className="icon-image-slider">
                      <img src={item?.image?.url} alt={item?.name} />
                    </div>
                    <div className="icon-text text-center">
                      {item?.name}
                    </div>
                  </div>
                ))}
              </Marquee>
              <Marquee direction="right" speed='30'>
                {indirectInvestment?.slice(halfLength).map((item, i) => (
                  <div className="slide" key={i}>
                    <div className="icon-image-slider">
                      <img src={item?.image?.url} alt={item?.name} />
                    </div>
                    <div className="icon-text text-center">
                      {item?.name}
                    </div>
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
