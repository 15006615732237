import React from "react";
import "./App.scss";
import { Router, Route, Switch } from "wouter";
import "./index.css";
import { GUI } from 'dat.gui';

import Footer from "./Footerstrat";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
// import { motion } from "framer-motion";

import { useLocation } from "react-router-dom";

import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Vision from "./Pages/Vision";
import Portfolio from "./Pages/Portfolio";
import Overview from "./Pages/Overview";
import Tab from "./Pages/Tab";

import {
  Canvas,
  extend,
  useFrame,
  useLoader,
  useThree,
} from "@react-three/fiber";

import "./App.css";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import circleImg from "./assets/e.png";
import { Suspense, useCallback, useMemo, useRef } from "react";

import { Helmet } from "react-helmet";

extend({ OrbitControls });

function CameraControls() {
  const {
    camera,
    gl: { domElement },
  } = useThree();

  const controlsRef = useRef();
  useFrame(() => controlsRef.current.update());

  return (
    <orbitControls
      ref={controlsRef}
      args={[camera, domElement]}
      autoRotate
      autoRotateSpeed={0}
      enableZoom={false}
    />
  );
}

function Points() {
  const imgTex = useLoader(THREE.TextureLoader, circleImg);
  const bufferRef = useRef();

  let t = 14;
  let f = 0.001; // Reduced frequency for fewer waves
  let a = 7; // Increased amplitude for taller waves
  // const gui = new GUI();
  let variables = {
    t: 14,
    f: 0.001,
    a: 7,
  };
  // gui.add(variables, 't', 0, 1000);
  // gui.add(variables, 'f', 0, 0.001);
  // gui.add(variables, 'a', 0, 200);
  
  const graph = useCallback(
    (x, z) => {
      return variables.a * Math.sin(variables.f * (x ** 2 + z ** 2 + variables.t)) + a * Math.cos(f * (x ** 2 + z ** 2 + t));
    },
    [t, f, a]
  );

  const count = 60;

  // const count2 = 120;

  const sep = 2;
  let positions = useMemo(() => {
    let positions = [];

    for (let xi = 0; xi < count; xi++) {
      for (let zi = 0; zi < count; zi++) {
        let x = sep * (xi - count / 2);
        let z = sep * (zi - count / 2);
        let y = graph(x / 1.2, z * 1); // Adjust x value to make the wave more concentrated to the left
        positions.push(x / 0.9, y, z * 2);
      }
    }

    return new Float32Array(positions);
  }, [count, sep, graph]);

  useFrame(() => {
    t += 2;

    const positions = bufferRef.current.array;

    let i = 0;
    for (let xi = 0; xi < count; xi++) {
      for (let zi = 0; zi < count; zi++) {
        let x = sep * (xi - count / 2);
        let z = sep * (zi - count / 2);

        positions[i + 1] = graph(x, z * 2);
        i += 3;
      }
    }

    bufferRef.current.needsUpdate = true;
  });

  return (
    <points>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          ref={bufferRef}
          attachObject={["attributes", "position"]}
          array={positions}
          count={positions.length / 3}
          itemSize={3}
        />
      </bufferGeometry>

      <pointsMaterial
        attach="material"
        map={imgTex}
        color={0xc3c6ca}
        size={0.35}
        sizeAttenuation
        transparent={false}
        alphaTest={0.5}
        opacity={1.0}
      />
    </points>
  );
}

function AnimationCanvas() {
  return (
    <Canvas
      colorManagement={false}
      camera={{ position: [100, 15, 29], near: 0.1, far: 1000, fov: 50 }}
    >
      <Points />

      <CameraControls />
    </Canvas>
  );
}

function HeaderView() {
  const location = useLocation();
  console.log(location.pathname);
  if (location.pathname === "/") {
    return (
      <motion.div
        className="anim"
        transition={{ duration: 3 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <Suspense fallback={<div></div>}>
          <AnimationCanvas />
        </Suspense>
      </motion.div>
    );
  } else {
    return null;
  }
}

function App() {
  return (
    <div>
      <div className="header-bar-wrapper"></div>
      <div className="app">
        <div className="backgroundme">
          <HeaderView />
        </div>

        <Helmet>
          <title>ID Theory || Home</title>
          <meta
            name="description"
            content="ID Theory are experienced blockchain investors on a mission to
      deliver optimal exposure to this emerging asset class."
          />
          <meta
            name="keywords"
            content="blockchain, decentralised, investment, fund, crypto, cryptoasset"
          />
        </Helmet>
        <div>
          <AnimatePresence exitBeforeEnter>
            <Router>
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/about" exact component={About} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/vision" exact component={Vision} />
                <Route path="/portfolio" exact component={Portfolio} />
                <Route path="/overview" exact component={Overview} />
              </Switch>
            </Router>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
}

export default App;
