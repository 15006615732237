import { React } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import logo from "./assets/idtlogo.png";
import { useLocation } from "react-router-dom";
import medium from "./assets/medium.png";

const NavbarPages = () => {
  return (
    <div
      className={`navbar-main ${
        window.location.pathname === "/" ? "navbar-main-inactive" : ""
      }`}
    >
      {/* <div
      className={
        window.location.pathname === "/"
          ? "navbar-main"
          : "navbar-main-inactive"
      }
    > */}
      <div
        className={
          window.location.pathname === "/" ? "home-active" : "home-inactive"
        }
      >
        <div className="wrapper nav-flex">
          <div
            className={
              window.location.pathname === "/"
                ? "nav-flex-inner-home"
                : "nav-flex-inner"
            }
          >
            <span
              className={
                window.location.pathname === "/overview"
                  ? "strategy--nav strategy--nav-active"
                  : "strategy--nav"
              }
            >
              <Link to="/overview">Overview</Link>
            </span>
            <span
              className={
                window.location.pathname === "/portfolio"
                  ? "portfolio--nav portfolio--nav-active"
                  : "portfolio--nav"
              }
            >
              <Link to="/portfolio">Portfolio</Link>
            </span>
            <span
              className={
                window.location.pathname === "/about"
                  ? "about--nav about--nav-active"
                  : "about--nav"
              }
            >
              <Link to="/about">About</Link>
            </span>

            <span
              className={
                window.location.pathname === "/contact"
                  ? "contact--nav contact--nav-active"
                  : "contact--nav"
              }
            >
              <Link to="/contact">Contact Us</Link>
            </span>
            <span className="research--nav">
              <img src={medium} />
              <a target="_blank" href="https://medium.com/id-theory">
                Research
              </a>
            </span>
            {window.location.pathname === "/" ? (
              ""
            ) : (
              <div className="logoend">
                <Link to="/">
                  <img src={logo} />
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarPages;
