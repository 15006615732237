import React, { useEffect } from "react";

import logo from "./svgs/logo.svg";

import NavbarPages from "../NavbarPages";
import { motion, useAnimation } from "framer-motion";
import Widenav from "../Widenav";
import ScrollToTopOnMount from "./ScrollToTopOnMount";

import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

function FadeInWhenVisible({ children }) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  controls.start("hidden");
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    // if (!inView) {
    //   controls.start('hidden');
    // }
  }, [controls, inView]);
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5, delay: 0.2 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
    >
      {children}
    </motion.div>
  );
}

export default function Home() {
  return (
    <div className="home-overflow">
      <ScrollToTopOnMount />
      <motion.div
        className="flex flex-col home-container..."
        // transition={{ duration: 0.5 }}
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
      >
        {/* <Navbar /> */}
        {/* <Widenav /> */}
        <NavbarPages />
        <motion.div
          className=""
          transition={{ duration: 0.5 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <div className="hero ">
            <div className="hero-inner">
              <img className="main-logo" src={logo} alt="main logo" />

              <h1 className="main-header">ID THEORY</h1>
              <h4 className="mt-10 text-center sub-header">
                a multi-strategy crypto fund at the forefront of emerging trends
              </h4>
              <Link to="/contact">
                <div className="mt-10 cta-button-wrap-outer">
                  <div className="cta-button-wrap">
                    <button className="cta-button">GET IN TOUCH</button>
                  </div>
                </div>
              </Link>
            </div>
            <p className="rights-text">
              All Rights Reserved. Not a Public Solicitation.
            </p>
          </div>
        </motion.div>
        {/* <img
        src={waves}
        alt="waves"
        className="waves"
    
      /> */}
      </motion.div>
    </div>
  );
}
