import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { pageVariants } from "../animations";
import Footerabout from "../Footerabout";
import NavbarPages from "../NavbarPages";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Helmet } from "react-helmet";
import { getContentfulData } from "../hook/getContentfulData";
import ScrollToTopOnMount from "./ScrollToTopOnMount";
import ParticlesBackground from "./particlesBackground";

export default function About() {
  const [data, setData] = useState(null);

  const query = `{
    aboutUsCollection(limit:1){
       items{
        aboutDesc
        topPersonsDataCollection{
          items{
            personName
            personImg{
              url
            }
            positionName
            positionName
            personDesc{
              json
            }
          }
        }

        secondLevelManagersCollection{
          items{
            name
            position
            image{
              url
            }
          }
        }

        directorsDataCollection{
          items{
            name
            image{
              url
            }
          }
        }

        clientTestimonialsCollection{
          items{
            clientName
            projectOrCompanyName
            
            image{
              url
            }
            
            description{
              json
            }
          }
        }

      }
    }
  }`;
  function adjustTextForNarrowDivs() {
    document.querySelectorAll('.advisor-flex-inner').forEach(div => {
      const h4 = div.querySelector('h4.ac-duo-text-head');
      if (div.offsetWidth < 250) {
        h4.classList.add('narrowrr');
      } else {
        h4.classList.remove('narrowrr');
      }
    });
  }
  
  // Run the function on page load and window resize
  window.onload = adjustTextForNarrowDivs;
  window.onresize = adjustTextForNarrowDivs;
  
  
  const handleGetData = async () => {
    const res = await getContentfulData(query);
    const {
      data: {
        aboutUsCollection: { items },
      },
    } = res;
  
    if (items.length > 0) {
      // Sort secondLevelManagersCollection in reverse alphabetical order by name
      const sortedManagers = items[0]?.secondLevelManagersCollection?.items?.sort((a, b) => {
        if (a.name < b.name) return 1;
        if (a.name > b.name) return -1;
        return 0;
      }) || [];
  
      setData({
        aboutDesc: items[0]?.aboutDesc ? items[0]?.aboutDesc : "",
        topOfficers:
          items[0]?.topPersonsDataCollection.items.length > 0
            ? items[0]?.topPersonsDataCollection.items
            : [],
        managers: sortedManagers,
        directors:
          items[0]?.directorsDataCollection?.items.length > 0
            ? items[0]?.directorsDataCollection?.items
            : [],
        testimonials:
          items[0]?.clientTestimonialsCollection?.items.length > 0
            ? items[0]?.clientTestimonialsCollection?.items
            : [],
      });
  
      console.log(`Number of topOfficers: ${items[0]?.topPersonsDataCollection.items.length}`);
      console.log(`Number of managers: ${sortedManagers.length}`);
      console.log(`Number of directors: ${items[0]?.directorsDataCollection?.items.length}`);
      console.log(`Number of testimonials: ${items[0]?.clientTestimonialsCollection?.items.length}`);
    }
  };
  

  useEffect(() => {
    handleGetData();
  }, []);
  function printManagerCount() {
    console.log(`Number of managers: ${data?.managers?.length}`);
  }
  return (
    <motion.div>
      <Helmet>
        <title>ID Theory || About</title>
        <meta
          name="description"
          content="ID Theory is an agile outfit, comprised of knowledgeable and passionate team members"
        />
      </Helmet>
      <ParticlesBackground />
      <ScrollToTopOnMount />
      <NavbarPages />
      <motion.div
        className="about"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <printManagerCount />
        <div className="wrapper lg:mb-40 md:mb-40 sm:mb-20 mb-10">
          <div className="vms-header">
            <h2 className="vms-header-about">About</h2>
          </div>
          <h4 className="about-subheader lg:mb-10 md:mb-5 sm:mb-2 mb-1">
            {data?.aboutDesc}
          </h4>

          <div className="md:mb-16 sm:mb-16 mb-10">
            <div className="md:mb-18 sm:mb-10 mb-5">
              <h3 className="about-header  text-left">THE TEAM</h3>
            </div>
          </div>

          <div className="xl:mx-auto lg:mb-40 sm:mb-10 mb-5 ac-duo">
            {data?.topOfficers.length > 0 &&
              data?.topOfficers.map((item, index) => (
                <div className="ac-duo-james">
                  <div className="ac-duo-text">
                    <h4 className="ac-duo-text-head ac-duo-text-head-j">
                      {item?.personName}
                    </h4>
                    <h5 className="ac-duo-text-sub">{item?.positionName}</h5>
                    <p className="ac-duo-text-body">
                      <p>
                        {" "}
                        {documentToReactComponents(item?.personDesc?.json)}
                      </p>
                    </p>
                  </div>
                  <div className="ac-duo-img lg:mb-20 sm:mb-10 mb-5">
                    <img
                      src={item?.personImg?.url}
                      alt="James Brodie Headshot"
                    ></img>
                  </div>
                </div>
              ))}
          </div>

          <div className="md:mb-18 sm:mb-10 mb-5"></div>
          <div className="xl:mx-auto lg:mb-20 sm:mb-10 mb-5 ac-duo-advisor">
            {data?.managers?.length > 0 &&
              (console.log(`Number of managers: ${data?.managers.length}`),
              data?.managers.slice(0, 4).map((item, index) => (
                <div className="advisor-flex-wrap">
                  <div className="mb-6 advisor-image">
                    <img
                      src={item?.image.url}
                      alt={`Nancy Headshot-${index}`}
                    ></img>
                  </div>
                  <div className="advisor-flex-inner px-5">
                    <h4 className="ac-duo-text-head ac-duo-text-head-advisors text-center ">
                      {item?.name}
                    </h4>
                    <h5 className="ac-duo-text-sub text-center">
                      {item?.position}
                    </h5>
                  </div>
                </div>
              )))}
          </div>

          <div className="md:mb-18 sm:mb-10 mb-5">
            <h3 className="ibd">INDEPENDENT DIRECTORS</h3>
          </div>
          <div className="contact-trio ">
            {data?.directors.length > 0 &&
              data?.directors.map((item, index) => (
                <div className="advisor-flex-wrap">
                  <div className="mb-6 advisor-image">
                    <img src={item?.image.url} alt="Nancy Headshot"></img>
                  </div>
                  <div className="advisor-flex-inner px-5">
                    <h4 className="ac-duo-text-head ac-duo-text-head-advisors text-center ">
                      {item?.name}
                    </h4>
                    <br></br> <br></br> <br></br>
                  </div>
                </div>
              ))}
          </div>
          <section className="testimonial-section">
  <h2 className="testimonials-h2">Testimonials</h2>
  <div className="testimonials">
    {data?.testimonials &&
      data.testimonials
        .filter(
          (item) =>
            item?.clientName && item?.projectOrCompanyName && item?.description
        )
        .map((item, index) => {
          const commonContent = (
            <>
              <div className="testimonial-header">
                <img className="testimonial-image" src={item?.image?.url} />
                <div className="testimonial-header-text">
                  <h3 className="testimonial-title">{item?.clientName}</h3>
                  <h4 className="testimonial-subtitle">
                    {item?.projectOrCompanyName}
                  </h4>
                </div>
              </div>
            </>
          );

          return (
            <div className="testimonial-wrapper" key={index}>
              <div className="testimonial">
                <div className="testimonial-inner">
                  {commonContent}
                  {index % 2 === 0 ? (
                    documentToReactComponents(item?.description.json)
                  ) : (
                    <p>{documentToReactComponents(item?.description.json)}</p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
  </div>
</section>

        </div>
      </motion.div>
      <Footerabout />
    </motion.div>
  );
}
